import axios from 'axios';

const currentUrl = window.location.href;
let BASE_URL = '';

if (currentUrl.includes("clickrifas.com")) {
  BASE_URL = "https://api.clickrifas.com/api/v1";
} else if (currentUrl.includes("devaribox.co")) {
  BASE_URL = "https://clickpromo-api.devaribox.co/api/v1";
} else if (currentUrl.includes(":300")) {
  // BASE_URL = "https://api.clickrifas.com/api/v1";
  // BASE_URL = "https://clickpromo-api.devaribox.co/api/v1";
  BASE_URL = "http://localhost:3001/api/v1";
}

const provider = axios.create({
  baseURL: BASE_URL,
});

export default provider;
